import React from 'react';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import PhoneNumber                   from '@interness/web-core/src/components/text/PhoneNumber/PhoneNumber';
import useClient                     from '@interness/users-addon/src/hooks/useClient';

import * as S from './styles';
import Card   from '../Card/Card';
import Error  from '../Error/Error';

const Partners = () => {

  const { loading, data, error } = useClient('partner', {
    fields: '*.*',
    sort: 'title'
  }, null, true);

  return (
    <div>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      {error && <Error message='Partner nicht gefunden' />}
      {data && <S.List>
        {data.map(partner => <li key={partner.id}>
          <Card>
            <h3>{partner.title}</h3>
            <img src={partner.logo.data.full_url} alt={partner.title}/>
            {partner.description && <p>{partner.description}</p>}
            <S.Details>
              <li><b>Anschrift: </b><span>{partner.street}, {partner.zip} {partner.place}</span></li>
              {partner.phone_number &&
              <li><b>Telefon: </b><span><PhoneNumber number={partner.phone_number}/></span></li>}
              {partner.fax_number &&
              <li><b>Fax: </b><span><PhoneNumber callable={false} number={partner.fax_number}/></span></li>}
              {partner.email &&
              <li><b>Email: </b><span><Link external to={`mailto:${partner.email}`}>{partner.email}</Link></span></li>}
              {partner.homepage &&
              <li><b>Homepage: </b><span><Link external to={partner.homepage}>{partner.homepage}</Link></span></li>}
            </S.Details>
          </Card>
        </li>)}
      </S.List>}
    </div>
  )
};

export default Partners;